import config from "@/config";
import axios from "axios";
import dayjs from "dayjs";
import firebase from "../firebase.client";
import {
  Article,
  EnhancedArticle,
  RecommendedArticle,
} from "@/types/article/Article";
import configClient from "@/config.client";

class ArticleClient {
  private endpoint: string;

  constructor() {
    this.endpoint = config.CONTENTFUL_EDIT || configClient.CONTENTFUL_EDIT;
  }

  async getArticleCount(): Promise<number> {
    const query = this.getArticleCountQuery();
    try {
      const articlesCount = await axios.post(this.endpoint, { query });
      return articlesCount?.data?.data?.articleCollection?.total;
    } catch (err) {
      throw new Error(`failed to fetch article count ${err?.message}`);
    }
  }

  async getArticlesByIds(ids): Promise<RecommendedArticle[]> {
    const query = this.getArticlesByIdsQuery();
    const variables = { ids };

    try {
      const articles = await axios.post(this.endpoint, { query, variables });
      return articles?.data?.data?.articleCollection?.items;
    } catch (err) {
      throw new Error(`failed to fetch articles ${err?.message}`);
    }
  }

  async getArticles(limit?: number, skip = 0): Promise<Article[]> {
    const query = this.getArticlesQuery(skip);
    try {
      const articles = await axios.post(this.endpoint, { query });
      let limitedArticles = articles?.data?.data?.articleCollection?.items;
      if (limit) {
        limitedArticles = limitedArticles.slice(0, limit);
      }
      return limitedArticles;
    } catch (err) {
      throw new Error(`failed to fetch articles ${err?.message}`);
    }
  }

  async getEnhancedArticles(limit: number = 20): Promise<EnhancedArticle[]> {
    let fbArticleData;
    let fbAuthorData;

    try {
      fbArticleData = (
        await firebase.firestore().collection("articles").get()
      ).docs.map((d) => d.data());
      fbAuthorData = (
        await firebase.firestore().collection("authors").get()
      ).docs.map((d) => d.data());
    } catch (err) {
      throw new Error(`Firestore call failed: ${err.message}`);
    }

    const articlesCount = await this.getArticleCount();

    let data = [];
    for (let skip = 0; skip < articlesCount; skip += 100) {
      data = data.concat(await this.getArticles(skip));
    }

    const enhancedArticles = data
      .map((article) => this.addInfo(article, fbArticleData, fbAuthorData))
      .sort((a, b) => b.firstPublished - a.firstPublished)
      .slice(0, limit);

    return enhancedArticles;
  }

  async getArticleAuthorByEmail(email: string): Promise<string | null> {
    let authorsWithMatchingEmail: firebase.firestore.DocumentData;

    try {
      const authorsRef = firebase.firestore().collection("authors");
      authorsWithMatchingEmail = await authorsRef
        .where("email", "==", email)
        .get();

      if (authorsWithMatchingEmail.empty || !authorsWithMatchingEmail.docs[0])
        return null;
    } catch (error) {
      throw new Error(`Firestore call failed: ${error.message}`);
    }

    return authorsWithMatchingEmail.docs[0].data().name || null;
  }

  private addInfo(article, fbArticleData, fbAuthorData) {
    const fbArticle = fbArticleData.find((a) => a.id === article.sys.id);
    const fbAuthor =
      fbAuthorData.find((a) => a.email === article.author) ||
      fbAuthorData.find((a) => a.email === "digital@allbright.co");
    const firstPublished =
      fbArticle?.firstPublished ||
      dayjs(article.sys.firstPublishedAt).unix() ||
      null;

    return { ...article, ...fbArticle, author: fbAuthor, firstPublished };
  }

  private getArticleCountQuery(): string {
    return `{articleCollection {total}}`;
  }

  private getArticlesQuery(skip: number, limit: number = 100): string {
    return `{
      articleCollection(limit: ${limit}, skip: ${skip}) {
        items {
          author
          title
          topic
          description
          url
          sys {id firstPublishedAt}
          heroImage {
            image {title url}
          }
        }
      }
    }`;
  }

  private getArticlesByIdsQuery(): string {
    return `query getByIds($ids: [String]) {
      articleCollection(where: {sys: {id_in: $ids} }) {
        items {
          author
          title
          topic
          description
          url
          sys {id firstPublishedAt}
          heroImage {
            image {title url}
          }
        }
      }
    }`;
  }

  public static getStaticHomepageArticleIds(): string[] {
    return [
      "19FgoKQPUT6Mgn8o6976a3",
      "7mwDSaxeqnW00rPTZe0Keo",
      "3g5sBo1TZMP1Hwv9ONX5vG",
      "1yUCaP8GQR1X0teDG6ZRUA",
      "LwQTBM8xmj1wjTALQPOGS",
      "5XWP7vBvwdiAEv75TgtHLA",
      "6DOSE4Ab7W2tRjt2h1kks9",
      "3YkYKuumPHPtuSeVcx2iPI",
    ];
  }
}

export default ArticleClient;
